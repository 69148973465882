import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { EventCampaignCategory } from '@models/campaigns/event-campaign-category.enum';
import { SearchBoxComponent } from '@modules/shared/components/search-box/search-box.component';
import { TranslateModule } from '@ngx-translate/core';
import { AffiliateProductsService } from '@services/affiliate-products.service';
import { AffiliateProductStatus } from '@models/affiliate-products/affiliate-product-status.enum';
import { EventCategoriesService } from '@services/event-categories.service';
import { EventCategory } from '@models/event-categories/event-category.model';
import { FormsModules } from '@utils/shared-modules';
import { AffiliateProductPartner } from '@models/affiliate-products/affiliate-product-partner.enum';
import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import { EventTemplateAccess } from '@models/design-templates/event-template-access.enum';
import { EventTemplateRelevance } from '@models/design-templates/event-template-relevance.enum';
import { EventTemplateService } from '@services/events/event-template.service';
import { EventTemplate } from '@models/design-templates/event-template.model';
import { EventTemplateAssetType } from '@models/events/event-template-asset-type.enum';
import { EventCampaignStatus } from '@models/campaigns/event-campaign-status.enum';
import { EventCampaignPartyStatus } from '@models/campaigns/event-campaign-party-status.enum';
import { EventCampaignWorkingStatus } from '@models/campaigns/event-campaign-working-status.enum';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    SearchBoxComponent,
    TranslateModule,
    FormsModules,
  ],
  providers: [
    EventCategoriesService,
    EventTemplateService,
    AffiliateProductsService,
  ],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss',
  animations: [
    trigger('statusMenuAnimation', [
      state('visible', style({ opacity: 1, height: '*' })),
      state('hidden', style({ opacity: 0, height: '0' })),
      transition('visible <=> hidden', animate('150ms ease-in-out')),
    ]),
    trigger('accessMenuAnimation', [
      state('visible', style({ opacity: 1, height: '*' })),
      state('hidden', style({ opacity: 0, height: '0' })),
      transition('visible <=> hidden', animate('150ms ease-in-out')),
    ]),
    trigger('relevanceMenuAnimation', [
      state('visible', style({ opacity: 1, height: '*' })),
      state('hidden', style({ opacity: 0, height: '0' })),
      transition('visible <=> hidden', animate('150ms ease-in-out')),
    ]),
    trigger('categoryMenuAnimation', [
      state('visible', style({ opacity: 1, height: '*' })),
      state('hidden', style({ opacity: 0, height: '0' })),
      transition('visible <=> hidden', animate('150ms ease-in-out')),
    ]),
    trigger('subCategoryMenuAnimation', [
      state('visible', style({ opacity: 1, height: '*' })),
      state('hidden', style({ opacity: 0, height: '0' })),
      transition('visible <=> hidden', animate('150ms ease-in-out')),
    ]),
    trigger('partnerMenuAnimation', [
      state('visible', style({ opacity: 1, height: '*' })),
      state('hidden', style({ opacity: 0, height: '0' })),
      transition('visible <=> hidden', animate('150ms ease-in-out')),
    ]),
  ],
})
export class FilterComponent implements OnInit {
  searchText: string = '';

  statusOptions: AffiliateProductStatus[] =
    AffiliateProductStatus.getAllAffiliateProductStatuses();
  statusMenuOpen: boolean = false;
  selectedStatus: AffiliateProductStatus = AffiliateProductStatus.ACTIVE;

  accessOptions: EventTemplateAccess[] =
    EventTemplateAccess.getAllEventTemplateAccesses();
  accessMenuOpen: boolean = false;
  selectedAccess?: EventTemplateAccess;

  relevanceOptions: EventTemplateRelevance[] =
    EventTemplateRelevance.getAllEventTemplateRelevances();
  relevanceMenuOpen: boolean = false;
  selectedRelevance?: EventTemplateRelevance;

  allCategories: EventCategory[] = [];

  categories: EventCategory[] = [];
  categoryMenuOpen: boolean = false;
  selectedCategories: EventCategory[] = [];

  subcategories: EventCategory[] = [];
  subCategoryMenuOpen: boolean = false;
  selectedSubcategories: EventCategory[] = [];

  partners: AffiliateProductPartner[] = [];
  partnerMenuOpen: boolean = false;
  selectedPartners: string[] = [];

  // event template assets specific filters
  assetTypeOptions: EventTemplateAssetType[] =
    EventTemplateAssetType.getAllEventTemplateTypes();
  assetTypeMenuOpen: boolean = false;
  selectedAssetType?: EventTemplateAssetType;

  // campaign events specific filters
  eventCampaignCategoryOptions =
    EventCampaignCategory.getAllEventCampaignCategories();
  eventCampaignCategoryMenuOpen: boolean = false;
  selectedEventCampaignCategory?: EventCampaignCategory;

  eventCampaignStatusOptions =
    EventCampaignStatus.getAllEventCampaignStatuses();
  eventCampaignStatusMenuOpen: boolean = false;
  selectedEventCampaignStatus?: EventCampaignStatus;

  eventCampaignPartyStatusOptions =
    EventCampaignPartyStatus.getAllEventCampaignPartyStatuses();
  eventCampaignPartyStatusMenuOpen: boolean = false;
  selectedEventCampaignPartyStatus?: EventCampaignPartyStatus;

  eventCampaignWorkingStatusOptions =
    EventCampaignWorkingStatus.getAllEventCampaignWorkingStatuses();
  eventCampaignWorkingStatusMenuOpen: boolean = false;
  selectedEventCampaignWorkingStatus?: EventCampaignWorkingStatus;

  filteredAffiliateProducts: AffiliateProduct[] = [];
  filteredEventTemplates: EventTemplate[] = [];
  filteredDistinctNames: string[] = [];

  @Input() isEventTemplatesListPage: boolean = false;
  @Input() isAffiliateProductsListPage: boolean = false;
  @Input() isEventTemplateAssetsListPage: boolean = false;
  @Input() isEventTemplateFramesListPage: boolean = false;
  @Input() isPromptListPage: boolean = false;
  @Input() isCampaignListPage: boolean = false;
  @Input() isCampaignEventList: boolean = false;
  @Input() isUserListPage: boolean = false;

  @Output() filterChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private eventCategoriesService: EventCategoriesService,
    private eventTemplateService: EventTemplateService,
    private affiliateProductsService: AffiliateProductsService,
  ) {}

  ngOnInit(): void {
    this.eventCategoriesService.getEventCategories().subscribe((data) => {
      this.allCategories = data;

      this.categories = this.allCategories
        .filter((item: EventCategory) => !item.parentCategoryId)
        .sort((a, b) => a.name.localeCompare(b.name));
      this.subcategories = this.allCategories
        .filter((item: EventCategory) => item.parentCategoryId)
        .sort((a, b) => a.name.localeCompare(b.name));
    });

    this.refreshFilter();
  }

  onCreateNewProduct(event: boolean): void {
    // Check if the 'currentPage' or 'products' have changed
    if (event) {
      this.refreshFilter();
    }
  }

  refreshFilter() {
    const filters = new Map<string, any>();

    if (this.searchText) {
      filters.set('searchTerm', this.searchText);
    }

    if (
      !this.isPromptListPage &&
      !this.isCampaignListPage &&
      !this.isCampaignEventList &&
      !this.isUserListPage
    ) {
      if (this.selectedCategories.length > 0) {
        if (this.selectedSubcategories.length > 0) {
          filters.set(
            'eventSubCategoryIds',
            this.selectedSubcategories.map((sc) => sc.id),
          );
        } else {
          filters.set(
            'eventCategoryIds',
            this.selectedCategories.map((c) => c.id),
          );
        }
      } else {
        filters.set(
          'eventSubCategoryIds',
          this.selectedSubcategories.map((sc) => sc.id),
        );
      }
    }

    if (this.isEventTemplatesListPage) {
      if (this.selectedAccess) {
        filters.set('access', this.selectedAccess);
      }

      if (this.selectedRelevance) {
        filters.set('relevance', this.selectedRelevance);
      }

      const userLang = localStorage.getItem('userLang');
      if (userLang) {
        filters.set('lang', userLang);
      }

      this.eventTemplateService.getAll(filters).subscribe((data) => {
        this.filteredEventTemplates = data;

        if (this.filteredEventTemplates) {
          this.filteredDistinctNames = Array.from(
            new Set(
              this.filteredEventTemplates
                .map((evenTemplate) => evenTemplate.name)
                .filter((name): name is string => name !== ''),
            ),
          );
        }
      });
    } else if (this.isAffiliateProductsListPage) {
      if (this.selectedStatus) {
        filters.set('status', this.selectedStatus);
      }

      if (this.selectedPartners.length > 0) {
        filters.set('partners', this.selectedPartners);
      }

      this.affiliateProductsService
        .getAllAffiliateProducts(filters)
        .subscribe((data) => {
          this.filteredAffiliateProducts = data;

          if (
            this.filteredAffiliateProducts?.length > 0 ||
            this.selectedPartners?.length === 0
          ) {
            this.partners = Array.from(
              new Set(
                this.filteredAffiliateProducts?.map(
                  (product) => product?.network,
                ),
              ),
            ).sort((a, b) => a.localeCompare(<string>b));
          }

          this.filteredDistinctNames = Array.from(
            new Set(
              this.filteredAffiliateProducts?.map((product) => product?.name),
            ),
          );
        });
    } else if (this.isEventTemplateAssetsListPage) {
      if (this.selectedAssetType) {
        filters.set('type', this.selectedAssetType);
      }
    } else if (this.isCampaignEventList) {
      if (this.selectedEventCampaignCategory) {
        filters.set('campaignCategory', this.selectedEventCampaignCategory);
      }
      if (this.selectedEventCampaignStatus) {
        filters.set('campaignStatus', this.selectedEventCampaignStatus);
      }
      if (this.selectedEventCampaignPartyStatus) {
        filters.set(
          'campaignPartyStatus',
          this.selectedEventCampaignPartyStatus,
        );
      }
      if (this.selectedEventCampaignWorkingStatus) {
        filters.set(
          'campaignWorkingStatus',
          this.selectedEventCampaignWorkingStatus,
        );
      }
    }
  }

  onSearchTermChange(searchTerm: string) {
    this.searchText = searchTerm;
    this.refreshFilter();
    this.filterChange.emit(searchTerm);
  }

  toggleStatusMenu(): void {
    this.statusMenuOpen = !this.statusMenuOpen;

    if (this.categoryMenuOpen) {
      this.categoryMenuOpen = !this.categoryMenuOpen;
    }

    if (this.subCategoryMenuOpen) {
      this.subCategoryMenuOpen = !this.subCategoryMenuOpen;
    }

    if (this.partnerMenuOpen) {
      this.partnerMenuOpen = !this.partnerMenuOpen;
    }
  }

  selectStatus(status: AffiliateProductStatus): void {
    this.selectedStatus =
      this.selectedStatus === status ? AffiliateProductStatus.ACTIVE : status;
    this.refreshFilter();
    this.filterChange.emit(this.selectedStatus);
  }

  getDisplayStatusName(status: AffiliateProductStatus): string {
    return AffiliateProductStatus.getDisplayName(status);
  }

  toggleAccessMenu(): void {
    this.accessMenuOpen = !this.accessMenuOpen;

    if (this.relevanceMenuOpen) {
      this.relevanceMenuOpen = !this.relevanceMenuOpen;
    }

    if (this.categoryMenuOpen) {
      this.categoryMenuOpen = !this.categoryMenuOpen;
    }

    if (this.subCategoryMenuOpen) {
      this.subCategoryMenuOpen = !this.subCategoryMenuOpen;
    }
  }

  getDisplayAccessName(access: EventTemplateAccess): string {
    return EventTemplateAccess.getDisplayName(access);
  }

  selectAccess(access: EventTemplateAccess): void {
    this.selectedAccess = this.selectedAccess === access ? undefined : access;
    this.refreshFilter();
    this.filterChange.emit(this.selectedAccess);
  }

  toggleRelevanceMenu(): void {
    this.relevanceMenuOpen = !this.relevanceMenuOpen;

    if (this.accessMenuOpen) {
      this.accessMenuOpen = !this.accessMenuOpen;
    }

    if (this.categoryMenuOpen) {
      this.categoryMenuOpen = !this.categoryMenuOpen;
    }

    if (this.subCategoryMenuOpen) {
      this.subCategoryMenuOpen = !this.subCategoryMenuOpen;
    }
  }

  getDisplayRelevanceName(relevance: EventTemplateRelevance): string {
    return EventTemplateRelevance.getDisplayName(relevance);
  }

  selectRelevance(relevance: EventTemplateRelevance): void {
    this.selectedRelevance =
      this.selectedRelevance === relevance ? undefined : relevance;
    this.refreshFilter();
    this.filterChange.emit(this.selectedRelevance);
  }

  // asset type
  toggleAssetTypeMenu(): void {
    this.assetTypeMenuOpen = !this.assetTypeMenuOpen;

    if (this.categoryMenuOpen) {
      this.categoryMenuOpen = !this.categoryMenuOpen;
    }

    if (this.subCategoryMenuOpen) {
      this.subCategoryMenuOpen = !this.subCategoryMenuOpen;
    }
  }

  toggleEventCampaignCategoryMenu(): void {
    this.eventCampaignCategoryMenuOpen = !this.eventCampaignCategoryMenuOpen;

    if (this.categoryMenuOpen) {
      this.categoryMenuOpen = !this.categoryMenuOpen;
    }

    if (this.subCategoryMenuOpen) {
      this.subCategoryMenuOpen = !this.subCategoryMenuOpen;
    }
  }

  toggleeventCampaignStatusMenu(): void {
    this.eventCampaignStatusMenuOpen = !this.eventCampaignStatusMenuOpen;

    if (this.categoryMenuOpen) {
      this.categoryMenuOpen = !this.categoryMenuOpen;
    }

    if (this.subCategoryMenuOpen) {
      this.subCategoryMenuOpen = !this.subCategoryMenuOpen;
    }
  }

  toggleeventCampaignPartyStatusMenu(): void {
    this.eventCampaignPartyStatusMenuOpen =
      !this.eventCampaignPartyStatusMenuOpen;

    if (this.categoryMenuOpen) {
      this.categoryMenuOpen = !this.categoryMenuOpen;
    }

    if (this.subCategoryMenuOpen) {
      this.subCategoryMenuOpen = !this.subCategoryMenuOpen;
    }

    if (this.eventCampaignStatusMenuOpen) {
      this.eventCampaignStatusMenuOpen = !this.eventCampaignStatusMenuOpen;
    }

    if (this.eventCampaignWorkingStatusMenuOpen) {
      this.eventCampaignWorkingStatusMenuOpen =
        !this.eventCampaignWorkingStatusMenuOpen;
    }
  }

  toggleeventCampaignWorkingStatusMenu(): void {
    this.eventCampaignWorkingStatusMenuOpen =
      !this.eventCampaignWorkingStatusMenuOpen;

    if (this.categoryMenuOpen) {
      this.categoryMenuOpen = !this.categoryMenuOpen;
    }

    if (this.subCategoryMenuOpen) {
      this.subCategoryMenuOpen = !this.subCategoryMenuOpen;
    }

    if (this.eventCampaignStatusMenuOpen) {
      this.eventCampaignStatusMenuOpen = !this.eventCampaignStatusMenuOpen;
    }

    if (this.eventCampaignPartyStatusMenuOpen) {
      this.eventCampaignPartyStatusMenuOpen =
        !this.eventCampaignPartyStatusMenuOpen;
    }
  }

  getDisplayAssetTypeName(type: EventTemplateAssetType): string {
    return EventTemplateAssetType.getDisplayName(type);
  }

  selectAssetType(type: EventTemplateAssetType): void {
    this.selectedAssetType = this.selectedAssetType === type ? undefined : type;
    this.refreshFilter();
    this.filterChange.emit(this.selectAssetType);
  }

  selectEventCampaignCategory(category: EventCampaignCategory): void {
    this.selectedEventCampaignCategory =
      this.selectedEventCampaignCategory === category ? undefined : category;
    this.refreshFilter();
    this.filterChange.emit(this.selectedEventCampaignCategory);
  }

  selectEventCampaignStatus(status: EventCampaignStatus): void {
    this.selectedEventCampaignStatus =
      this.selectedEventCampaignStatus === status ? undefined : status;
    this.refreshFilter();
    this.filterChange.emit(this.selectedEventCampaignStatus);
  }

  selectEventCampaignPartyStatus(status: EventCampaignPartyStatus): void {
    this.selectedEventCampaignPartyStatus =
      this.selectedEventCampaignPartyStatus === status ? undefined : status;
    this.refreshFilter();
    this.filterChange.emit(this.selectedEventCampaignPartyStatus);
  }

  selectEventCampaignWorkingStatus(status: EventCampaignWorkingStatus): void {
    this.selectedEventCampaignWorkingStatus =
      this.selectedEventCampaignWorkingStatus === status ? undefined : status;
    this.refreshFilter();
    this.filterChange.emit(this.selectedEventCampaignWorkingStatus);
  }

  toggleCategoryMenu(): void {
    this.categoryMenuOpen = !this.categoryMenuOpen;

    if (this.statusMenuOpen) {
      this.statusMenuOpen = !this.statusMenuOpen;
    }

    if (this.accessMenuOpen) {
      this.accessMenuOpen = !this.accessMenuOpen;
    }

    if (this.relevanceMenuOpen) {
      this.relevanceMenuOpen = !this.relevanceMenuOpen;
    }

    if (this.subCategoryMenuOpen) {
      this.subCategoryMenuOpen = !this.subCategoryMenuOpen;
    }

    if (this.partnerMenuOpen) {
      this.partnerMenuOpen = !this.partnerMenuOpen;
    }
  }

  selectCategory(category: EventCategory): void {
    if (this.selectedCategories.includes(category)) {
      this.selectedCategories = this.selectedCategories.filter(
        (c) => c !== category,
      );
    } else {
      this.selectedCategories.push(category);
    }

    // Filter subcategories based on selected categories
    if (this.selectedCategories.length > 0) {
      this.subcategories = this.allCategories
        .filter((item: EventCategory) => item.parentCategoryId)
        .filter((subCategory) =>
          this.selectedCategories.some(
            (selectedCategory) =>
              subCategory.parentCategoryId === selectedCategory.id,
          ),
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    } else {
      this.subcategories = this.allCategories
        .filter((item: EventCategory) => item.parentCategoryId)
        .sort((a, b) => a.name.localeCompare(b.name));
    }

    this.refreshFilter();

    this.filterChange.emit(this.selectedCategories);
  }

  toggleSubCategoryMenu(): void {
    this.subCategoryMenuOpen = !this.subCategoryMenuOpen;

    if (this.statusMenuOpen) {
      this.statusMenuOpen = !this.statusMenuOpen;
    }

    if (this.accessMenuOpen) {
      this.accessMenuOpen = !this.accessMenuOpen;
    }

    if (this.relevanceMenuOpen) {
      this.relevanceMenuOpen = !this.relevanceMenuOpen;
    }

    if (this.categoryMenuOpen) {
      this.categoryMenuOpen = !this.categoryMenuOpen;
    }

    if (this.partnerMenuOpen) {
      this.partnerMenuOpen = !this.partnerMenuOpen;
    }
  }

  selectSubCategory(subCategory: EventCategory): void {
    if (this.selectedSubcategories.includes(subCategory)) {
      this.selectedSubcategories = this.selectedSubcategories.filter(
        (c) => c !== subCategory,
      );
    } else {
      this.selectedSubcategories.push(subCategory);
    }

    this.refreshFilter();

    this.filterChange.emit(this.selectedSubcategories);
  }

  getDisplayPartnerName(partner: AffiliateProductPartner) {
    return AffiliateProductPartner.getDisplayName(partner);
  }

  togglePartnerMenu(): void {
    this.partnerMenuOpen = !this.partnerMenuOpen;

    if (this.statusMenuOpen) {
      this.statusMenuOpen = !this.statusMenuOpen;
    }

    if (this.categoryMenuOpen) {
      this.categoryMenuOpen = !this.categoryMenuOpen;
    }

    if (this.subCategoryMenuOpen) {
      this.subCategoryMenuOpen = !this.subCategoryMenuOpen;
    }
  }

  selectPartner(partner: string): void {
    if (this.selectedPartners.includes(partner)) {
      this.selectedPartners = this.selectedPartners.filter(
        (c) => c !== partner,
      );
    } else {
      this.selectedPartners.push(partner);
    }

    this.refreshFilter();

    this.filterChange.emit(this.selectedPartners);
  }

  get searchBoxPlaceholder(): string {
    if (this.isEventTemplatesListPage) {
      return 'APP.DESIGN_TEMPLATES.SEARCH_TEMPLATES';
    } else if (this.isEventTemplateAssetsListPage) {
      return 'APP.TEMPLATE_ASSETS.SEARCH_ASSETS';
    } else if (this.isEventTemplateFramesListPage) {
      return 'APP.TEMPLATE_FRAMES.SEARCH_FRAMES';
    } else if (this.isPromptListPage) {
      return 'APP.PROMPTS.SEARCH';
    } else if (this.isCampaignListPage) {
      return 'APP.CAMPAIGNS.SEARCH';
    } else if (this.isCampaignEventList) {
      return 'APP.EVENTS.SEARCH';
    } else if (this.isUserListPage) {
      return 'APP.USERS.SEARCH';
    } else {
      return 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.SEARCH_PRODUCTS';
    }
  }

  protected readonly EventCampaignStatus = EventCampaignStatus;
  protected readonly EventCampaignPartyStatus = EventCampaignPartyStatus;
  protected readonly EventCampaignWorkingStatus = EventCampaignWorkingStatus;
  protected readonly EventCampaignCategory = EventCampaignCategory;
}
